<template>
  <cube-popup
    ref="myPopup"
    position="top"
    type="extend-popup"
  >
    <div
      class="filter-wrap"
      :style="{ 'height': height + 'px' }"
    >
      <div class="content-wrap">
        <cell
          v-for="(item, index) of formData"
          :key="index"
          :data="item"
        />
      </div>
      <div class="btn-wrap">
        <cube-button
          :light="true"
          class="reset-btn"
        >
          重置
        </cube-button>
        <cube-button @click="confirm">
          确认
        </cube-button>
      </div>
    </div>
  </cube-popup>
</template>

<script>
import cell from '_c/cell/index';
import { mapGetters } from 'vuex';
export default {
  name: 'FilterCommon',
  components: { cell },
  props: {
    filterData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters(['dictList'])
  },
  watch:{
    filterData(newValue){
      this.formData = JSON.parse(JSON.stringify(newValue))
    }
  },
  data() {
    return {
      height: window.innerHeight - 160,
      formData: []
    };
  },
  created() {
    this.formData = JSON.parse(JSON.stringify(this.filterData))
  },
  methods: {
    open() {
      this.$refs['myPopup'].show();
    },
    confirm() {
      this.$refs['myPopup'].hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.cube-extend-popup{
  .cube-popup-content{
    transition: all 2s;
  }
  .filter-wrap{
    position: relative;
    background-color: #F7F7F7;
    .content-wrap{
      padding-left: 15px;
      background: #fff;
    }
  }
  .btn-wrap{
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    .reset-btn{
      color: #00a4ff;
      background-color: #fff;
    }
    /deep/ .cube-btn{
      border-radius: 0;
    }
  }
}

</style>
