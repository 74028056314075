<template>
  <li
    class="screen-li"
    @click="showDep(data)"
  >
    <div class="screen-title">
      {{ data.title }}
    </div>
    <div class="screen-value">
      <span class="screen-val">{{ data.name }}</span>
      <i class="icon-joyo arrows">&#xe66c;</i>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  },
  methods: {
    showDep() {}
  }
};

</script>

<style lang="scss" scoped>
.screen-li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height:50px ;
  border-bottom:1px solid $color-E6;
  .screen-title{
    // width: 90px;
    color: $color-333;
    font-size: $font-15;
  }
  .screen-value{
    display: flex;
    flex:1;
    .screen-val{
      flex: 1;
      color:$color-333;
      font-size:$font-15;
      text-align: right;
      @extend .uniline-ellipsis;
    }
    .arrows{
      margin:0 15px 0 10px;
      color: $color-CCC;
      font-size: 11px;
    }
  }
}
.screen-li:last-child{
  border: 0;
}
</style>
