<template>
  <section>
    <cube-page
      type="tab-composite-view"
      title="tab-composite"
    >
      <div slot="content">
        <cube-tab-bar
          v-show="needFirstTab && tabList.length > 1"
          ref="tabNav"
          v-model="initialIndex"
          show-slider
          inline
          :use-transition="disabled"
          :style="{height: tabBarHeight ? tabBarHeight + 'px' : '53px', marginRight: rightInstance + 'px'}"
          :class="{'border-bottom-1px': needBorder}"
          @change="changePage"
        >
          <cube-tab
            v-for="item in tabList"
            :key="item.value"
            :label="item.value"
          >
            <div
              class="tabBtn"
              :class="{ 'gray-bg': backgroundGray, 'white-bg': !backgroundGray}"
            >
              {{ item.label }}
              <i
                v-if="needIcon && item.num"
                class="tabIcon"
              >{{ item.num + 0 > 99 ? '99+' : item.num }}</i>
            </div>
          </cube-tab>
        </cube-tab-bar>
        <slot name="search" />
        <div
          class="tab-slide-container"
          :style="{
            top: tabList.length > 1 ? fixedHeight / 16 + 53 / 16 + 'rem' : fixedHeight / 16 + 'rem',
            bottom: needBtn ? 74 + 'px' : 0
          }"
        >
          <!-- tab -->
          <cube-slide
            ref="slide"
            :loop="loop"
            :initial-index="initialIndex"
            :auto-play="autoPlay"
            :show-dots="showDots"
            :options="slideOptions"
            @scroll="scroll"
            @change="changePage"
          >
            <cube-slide-item
              v-for="(item, index) of tabList"
              :key="index"
              :class="needBorder && item.tableData ? 'cube-slide-list' : ''"
            >
              <cube-scroll
                v-if="isRefresh || (initialIndex === index)"
                ref="scroll"
                :data="item.tableData"
                :options="scrollOptions"
                @pulling-down="onPullingDown(index)"
                @pulling-up="onPullingUp(index)"
              >
                <slot :name="index + 1" />
              </cube-scroll>
            </cube-slide-item>
          </cube-slide>
          <!-- <div
            v-for="(item, index) of tabList"
            :key="index"
            :class="needBorder && item.tableData ? 'cube-slide-list' : ''"
          >
            <cube-scroll
              v-if="isRefresh || (initialIndex === index)"
              ref="scroll"
              :data="item.tableData"
              :options="scrollOptions"
              @pulling-down="onPullingDown(index)"
              @pulling-up="onPullingUp(index)"
            >
              <slot :name="index + 1" />
            </cube-scroll>
          </div> -->
        </div>
        <div
          v-if="rightInstance !== 0"
          class="filter-wrap"
          @click="showFilterWrap"
        >
          筛选
          <div class="icon-arrow" />
        </div>
      </div>
    </cube-page>
    <filter-com
      v-if="rightInstance !==0"
      ref="filterWrap"
      :filter-data="filterInfoList"
    />
  </section>
</template>

<script>
/* eslint-disable */
import CubePage from '_c/clue-page';
import FilterCom from '_c/fiterCommon';

export default {
  components: {
    CubePage,
    FilterCom
  },
  props: {
    fixedHeight: {
      type: String || Number
    },
    needBtn: {
      type: Boolean,
      default: false
    },
    tabList: {
      type: Array
    },
    loadEnable: {
      type: Boolean,
      default: true
    },
    refreshEnable: {
      type: Boolean,
      default: true
    },
    backgroundGray: {
      type: Boolean,
      default: false
    },
    needFirstTab: {
      type: Boolean,
      default: true
    },
    assignIndex: {
      type: Number
    },
    needBorder: {
      type: Boolean,
      default: false
    },
    needIcon: {
      type: Boolean,
      default: false
    },
    rightInstance: {
      type: Number,
      default : 0
    },
    tabBarHeight: {
      type: Number,
      default : 0
    },
    filterData: {
      type: Array,
      default() {
        return []
      }
    },
    isRefresh: {
      type: Boolean,
      default: true
    },
    // 是否滑动切换，默认true
    isPageToggle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      initialIndex: this.assignIndex || 0,
      disabled: false,
      loop: false,
      autoPlay: false,
      showDots: false,
      slideOptions: {
        momentumLimitDistance: 15,
        listenScroll: false,
        probeType: 3,
        click: false,
        /* lock y-direction when scrolling horizontally and  vertically at the same time */
        directionLockThreshold: 0
      },
      // pullDownRefresh: true,
      pullDownRefreshThreshold: 60,
      pullDownRefreshTxt: '刷新成功',
      // pullUpLoad: true,
      pullUpLoadThreshold: 0,
      pullUpLoadMoreTxt: '更多~',
      pullUpLoadNoMoreTxt: '到底了~',
      upload: false,
      filterInfoList: []
    };
  },
  computed: {
    scrollOptions() {
      return {
        pullDownRefresh: this.pullDownRefreshObj,
        pullUpLoad: this.pullUpLoadObj,
        scrollbar: true,
        directionLockThreshold: 0
      };
    },
    pullDownRefreshObj() {
      return this.refreshEnable
        ? {
          // eslint-disable-next-line radix
          threshold: parseInt(this.pullDownRefreshThreshold),
          txt: this.pullDownRefreshTxt
        }
        : false;
    },
    pullUpLoadObj() {
      return this.loadEnable
        ? {
          // eslint-disable-next-line radix
          threshold: parseInt(this.pullUpLoadThreshold),
          txt: {
            more: this.pullUpLoadMoreTxt,
            noMore: this.pullUpLoadNoMoreTxt
          },
          visible: false
        }
        : false;
    }
  },
  watch:{
    filterData(newValue){
      console.log(newValue, 'newValue')
      this.filterInfoList = newValue
    }
  },
  created() {
    // 不用watch 用wacth得强制刷新
    this.slideOptions.momentumLimitDistance = this.isPageToggle ? 15 : 100000
  },
  mounted() {
    console.log(this.assignIndex)
    if (this.rightInstance !== 0) {
      this.filterInfoList = JSON.parse(JSON.stringify(this.filterData))
    }
    this.$nextTick(() => {
      const dom = this.$refs.tabNav.$el.querySelector('.cube-tab-bar-slider')
      dom && (dom.style.left = `${(document.body.clientWidth / this.tabList.length - 30 - (this.rightInstance/2)) / 2}px`);
    });
  },
  methods: {
    onPullingDown(index) {
      console.log('下拉刷新');
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.scroll[this.initialIndex] && this.$refs.scroll[this.initialIndex].forceUpdate();
        })
        this.$parent.requestData.pageNum = 1;
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('updateList', '', 'refresh');
      }, 1000);
    },
    onPullingUp(index) {
      console.log('上拉加载', this.upload, this.initialIndex, index);
      setTimeout(() => {
        if (this.upload) {
          // 如果有新数据
          this.$parent.requestData.pageNum++;
          // eslint-disable-next-line vue/custom-event-name-casing
          this.$emit('updateList', '', 'upload');
        } else {
          // 如果没有新数据
          if(this.$refs.scroll[this.initialIndex]) {
            this.$refs.scroll[this.initialIndex].forceUpdate();
          } else {
            this.$refs.scroll[0].forceUpdate();
          }
        }
      }, 1000);
    },
    changePage(current) {
      this.initialIndex = current;
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('getIndex', current);
    },
    scroll(pos) {
      const x = Math.abs(pos.x);
      const tabItemWidth = this.$refs.tabNav.$el.clientWidth;
      const slideScrollerWidth = this.$refs.slide.slide.scrollerWidth;
      const deltaX = (x / slideScrollerWidth) * tabItemWidth;
      this.$refs.tabNav.setSliderTransform(deltaX);
    },
    resolveTitle(item) {
      return `${item.name}关注了问题 · ${item.postTime} 小时前`;
    },
    resolveQuestionFollowers(item) {
      return `${item.answers} 赞同 · ${item.followers} 评论`;
    },
    showFilterWrap(){
      this.$refs.filterWrap.open()
    }
  }
};
</script>

<style lang="scss" scoped>
.cube-slide-list{
  background-color: $color-F7;
}
.tab-composite-view {
  .cube-tab-bar {
    height: 53px;
  }
  .cube-tab,
  .cube-tab_active {
    color: #999BA3;
  }
  .cube-tab-bar-slider {
    background-color: black;
  }
  .tab-slide-container {
    position: fixed;
    // top: 53px;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .list-wrapper {
    overflow: hidden;
    li {
      margin-top: 10px;
      padding: 15px 10px;
      color: #999;
      font-size: 14px;
      white-space: normal;
      text-align: left;
      background-color: white;
      .line-height {
        line-height: 1.5;
      }
      .is-black {
        color: black;
      }
      .is-grey {
        color: #999;
      }
      .is-bold {
        font-weight: bold;
      }
      .top {
        display: flex;
        .avatar {
          width: 15px;
          height: 15px;
          margin-right: 2px;
          border-radius: 100%;
        }
        .time {
          flex: 1;
        }
      }
      .middle {
        display: flex;
        margin: 10px 0;
        color: black;
      }
      .hot-title {
        display: flex;
        align-items: center;
        font-size: 12px;
        .hot-sequence {
          display: inline-block;
          margin-right: 2px;
          padding: 3px 6px;
          color: white;
          background-color: darkgoldenrod;
          border-radius: 2px;
        }
      }
      .hot-content {
        margin-top: 15px;
      }
    }
  }
}
.gray-bg {
  background: $color-F7;
}
.white-bg {
  background: $color-FFF;
}
.filter-wrap {
  position: absolute;
  top: 20px;
  right: 30px;
  padding-left: 12px;
  font-size: 14px;
  border-left: 1px solid #e5e5e5;
  .icon-arrow{
    position: absolute;
    top: 0;
    right: -16px;
    width: 16px;
    height: 16px;
    background-image: url('~@/assets/img/icon_arrow_down.png');
    background-size: cover;
  }
}
</style>
